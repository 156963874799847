import { Component } from 'react';

import { ScalarQuestionWrapperProps } from 'survey/types';
import { ScalarQuestion, StarRatingTile } from 'survey/components';
import { getQuestionOptions } from 'survey/selectors';
import AppContext from 'survey/contexts/AppContext';

class StarRating extends Component<ScalarQuestionWrapperProps> {
  static contextType = AppContext;

  selectedCheck = (star: number): boolean => {
    const {
      answer: { option_ids }, // eslint-disable-line @typescript-eslint/naming-convention
      options,
    } = this.props;
    let selectedStar = 0;
    let optionId: number | undefined | string;

    if (option_ids) [optionId] = option_ids;

    if (optionId) {
      const selectedOption = getQuestionOptions(this.context, options).find(
        (option): boolean => option.id === optionId
      );

      if (selectedOption) {
        selectedStar = selectedOption.value;
      }
    }

    return star <= selectedStar;
  };

  handleStarClick = (optionId: number, value: number): void => {
    const { updateAnswer, id } = this.props;

    updateAnswer(id, { option_ids: [optionId], value });
  };

  render(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const scalar_options = {
      component: this.constructor.name,
      tileComponent: StarRatingTile,
      handleTileClick: this.handleStarClick,
      selectedCheck: this.selectedCheck,
      className: 'star-rating',
    };

    return <ScalarQuestion {...scalar_options} {...this.props} />;
  }
}

export default StarRating;
