import { SocialButtonProps } from 'survey/types';

export default function TwitterButton({
  url,
  buttonText,
  handleClick,
}: SocialButtonProps): JSX.Element {
  const onClick = (): void => handleClick();

  return (
    <a
      onClick={onClick}
      href={url}
      target="_blank"
      className="btn btn-block social-btn twitter"
      rel="noopener noreferrer"
      aria-label="Share on Twitter"
    >
      <i className="fab fa-twitter" />
      <span>{buttonText}</span>
    </a>
  );
}
