import StatusPage from './StatusPage';
import SurveyExpiredImage from 'survey/assets/images/survey-expired.svg';

const ExpiredPage = (): JSX.Element => {

  return (
    <StatusPage
      image={SurveyExpiredImage}
      headerText="Survey Expired"
    >
      Uh oh! This survey has expired and no answers can be recorded at this time.
    </StatusPage>
  );
};

export default ExpiredPage;
