import { FormEvent } from 'react';

import useLiquidTemplate from 'survey/hooks/useLiquidTemplate';
import { CommentProps } from 'survey/types';

function Comment({
  placeholder,
  updateAnswer,
  questionId,
  answer,
}: CommentProps): JSX.Element | null {
  const commentPlaceholder = useLiquidTemplate(placeholder || '');

  function updateComment(event: FormEvent<HTMLTextAreaElement>): void {
    updateAnswer(questionId, { comment: event.currentTarget.value as string });
  }

  function shouldRender(): boolean {
    const { option_ids } = answer; // eslint-disable-line @typescript-eslint/naming-convention

    return Boolean(option_ids && option_ids.length);
  }

  if (shouldRender()) {
    const { comment } = answer;

    return (
      <div className="comment-wrapper" data-testid="comments-wrapper">
        <label
          className="visuallyhidden comment-label"
          aria-label={commentPlaceholder}
          htmlFor={`question-${questionId}-comment`}
        >
          {commentPlaceholder}
        </label>

        <textarea
          id={`question-${questionId}-comment`}
          className="comment-textarea"
          placeholder={commentPlaceholder}
          value={comment}
          onChange={updateComment}
          rows={4}
        />
      </div>
    );
  }

  return null;
}

export default Comment;
