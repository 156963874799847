import { createContext } from 'react';
import { AppState } from '../types';

export const initialState: AppState = {
  isLoading: true,
  api_base_url: '',
  response_api_base_url: '',
  facebook_app_id: '',
  app_env: '',
  result: [],
  currentPage: 1,
  answers: {},
  finished: false,
  expired: false,
  texts: {},
  brand: {
    name: '',
    logo: '',
    primary_color: '',
    secondary_color: '',
  },
  submit: {
    text: 'Submit',
  },
  thank_you: {
    condition: 'false',
    social_action: null,
    texts: {},
    twitter_share_enabled: false,
    marketing_enabled: false,
    twitter_follow_enabled: false,
    twitter_handle: '',
    twitter_share_text: '',
    facebook_share_enabled: false,
    facebook_follow_enabled: false,
    facebook_share_hashtag: '',
    facebook_share_quote: '',
    facebook_share_link: '',
    facebook_follow_link: '',
    custom_link: '',
    custom_link_type: null,
    custom_link_image: '',
    custom_link_text: '',
    friendbuy_enabled: false,
    friendbuy_site_id: null,
    friendbuy_widget_id: null,
  },
  employee: {
    first_name: '',
    last_name: '',
    image: '',
    text: '',
    header_text: '',
    location: '',
  },
  footer: {
    text: '',
    hide: false,
    privacy_policy: '',
    powered_by: '',
    about_us: '',
  },
  links: {},
  channel: 'chat',
  language: 'en',
  custom_properties: {},
  feature_flags: {},
  global_texts: {
    next: 'Next',
    complete: 'Complete',
  },
};

export default createContext<AppState>(initialState);
