import 'core-js';
import { createRoot } from 'react-dom/client';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap4/dist/css/bootstrap.css';
import 'survey/assets/stylesheets/font-faces.css';
import Router from 'survey/containers/Router';
import InitializationContext from 'survey/contexts/InitializationContext';
import * as Sentry from '@sentry/browser';
import { initializeApm } from 'survey/apm';

require('survey/assets/stylesheets/App.scss');

// Config values provided by webpack
/* eslint-disable no-undef */
let initializationContext = {
  REACT_APP_API_BASE_URL,
  REACT_APP_FACEBOOK_APP_ID,
  REACT_APP_RESPONSE_API_BASE_URL,
  REACT_APP_ENV,
  REACT_APP_SENTRY_DSN,
  APM_URL,
  APM_ENABLED,
};
/* eslint-enable no-undef */

if (initializationContext.REACT_APP_ENV !== 'test') {
  Sentry.init({
    dsn: initializationContext.REACT_APP_SENTRY_DSN,
    environment: initializationContext.REACT_APP_ENV,
    release: process.env.REACT_APP_GIT_SHA,
    sampleRate: 0.25,
    ignoreErrors: [
      'Request aborted',
      'ResizeObserver loop',
      "null is not an object (evaluating 'n.title')",
    ],
  });

  Sentry.configureScope(scope => {
    const subdomain = document.location.hostname.split('.')[0];
    scope.setTag('company', subdomain);
  });

  const apm = initializeApm(initializationContext);
  initializationContext = { ...initializationContext, apm };
}

const Wrapper = () => (
  <InitializationContext.Provider value={initializationContext}>
    <Router />
  </InitializationContext.Provider>
);

const surveyAppRoot = createRoot(document.getElementById('root'));

surveyAppRoot.render(
    <Wrapper />
);

if (module.hot) {
  module.hot.accept();
}
