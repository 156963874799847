import { init as initApm, ApmBase } from '@elastic/apm-rum';
import { RunTimeConfigProps } from './types';

export const initializeApm = (
  runTimeConfiguration: RunTimeConfigProps
): ApmBase => {
  const { REACT_APP_ENV, APM_URL, APM_ENABLED } = runTimeConfiguration;

  const { REACT_APP_GIT_SHA } = process.env;

  let active = Math.random() < 0.2;
  if (!APM_ENABLED) {
    active = false;
  }
  const subdomain = window.location.host.split('.')[0];

  const apm = initApm({
    serviceName: 'Surveys',
    serverUrl: APM_URL,
    serviceVersion: REACT_APP_GIT_SHA,
    environment: REACT_APP_ENV,
    active,
  });

  apm.setInitialPageLoadName(subdomain);

  return apm;
};
