import { Component } from 'react';
import classNames from 'classnames';

import { TileProps } from 'survey/types';

class Tile extends Component<TileProps> {
  handleTileClick = (): void => {
    const { id, value, handleTileClick } = this.props;

    handleTileClick(id, value);
  };

  render(): JSX.Element {
    const { text, subtitle, selected, index, id, imageUrl } = this.props;

    return (
      <div className="tile-button-wrapper">
        <button
          type="button"
          tabIndex={index}
          className={classNames('btn btn-info', { active: selected })}
          data-testid={`tile-button-${id}`}
          onClick={this.handleTileClick}
        >
          {imageUrl && (<img data-testid={`tile-image-${id}`} src={imageUrl} alt={text} />)}
          <span>{text}</span>
        </button>

        {subtitle && <div className="tile-subtitle">{subtitle}</div>}
      </div>
    );
  }
}

export default Tile;
