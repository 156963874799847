import { useContext } from 'react';
import TemplateText from 'survey/components/TemplateText';
import { AppState } from 'survey/types';
import AppContext from 'survey/contexts/AppContext';
import AgentConnectLogoDark from 'survey/assets/images/agent_connect_dark.png';

const utmCampaign = 'ftr';
const utmMedium = 'web';
const utmSource = 'sc';
const baseUrl = 'https://www.medallia.com';

const Footer = (): JSX.Element | null => {
  const appContext = useContext<AppState>(AppContext);
  const defaultPrivacyUrl = `${baseUrl}/privacy-policy`;
  const defaultAboutUrl = `${baseUrl}/products/agent-connect/?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${appContext.brand.name}&utm_campaign=${utmCampaign}`;

  if (!appContext.employee) return null;

  const { about_us: aboutUrl, privacy_policy: privacyUrl } = appContext.links;
  const footerText = appContext.texts.footer || appContext.footer.text;

  return (
    <div className="row justify-content-center">
      <footer
        className="branded-footer text-center col-xs-12 col-sm-8 col-md-6"
        data-testid="footer"
      >
        {!!footerText && (
          <div
            className="email-footer dynamic-text"
            aria-label={footerText}
          >
            <TemplateText escape={false} allowATags text={footerText} />
          </div>
        )}

        {!appContext.footer.hide && (
          <div className="powered-by">
            <a
              tabIndex={-99}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="about-us-logo"
              href={aboutUrl || defaultAboutUrl}
            >
              <span>{appContext.footer.powered_by}</span>
              <img src={AgentConnectLogoDark} alt="Agent Connect logo" />
            </a>
          </div>
        )}

        <div className="row justify-content-center">
          {!appContext.footer.hide && (
            <a
              tabIndex={-100}
              className="d-block"
              target="_blank"
              rel="noreferrer noopener"
              data-testid="about-us-link"
              href={aboutUrl || defaultAboutUrl}
            >
              {appContext.footer.about_us}
            </a>
          )}

          <a
            href={privacyUrl || defaultPrivacyUrl}
            className="d-block"
            rel="noreferrer noopener"
            data-testid="privacy-link"
            target="_blank"
          >
            {appContext.footer.privacy_policy}
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
