import { ReactNode, useEffect } from 'react';
import { FriendbuyProps } from 'survey/types';
import useScript from 'survey/hooks/useScript';

declare global {
  interface Window {
    friendbuy: {
      push: (data: (string | null)[]) => void;
    };
  }
}
export default function Friendbuy({
  siteId,
  widgetId,
}: FriendbuyProps): ReactNode {
  useEffect(() => {
    const friendbuy = (window.friendbuy = window.friendbuy || []);

    friendbuy.push(['site', siteId]);
    friendbuy.push(['widget', widgetId]);
  }, [siteId, widgetId]);

  useScript('//djnf6e5yyirys.cloudfront.net/js/friendbuy.min.js');

  return <div className={`friendbuy friendbuy-${widgetId}`} />;
}
