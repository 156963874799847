import { Component, FormEvent } from 'react';

import { QuestionComponentProps, QuestionOption } from 'survey/types';
import { TemplateText } from 'survey/components';
import { getQuestionOptions } from 'survey/selectors';
import AppContext from 'survey/contexts/AppContext';

class MultipleChoice extends Component<QuestionComponentProps> {
  static contextType = AppContext;

  handleOptionClick = (event: FormEvent<HTMLInputElement>): void => {
    const { updateAnswer, id } = this.props;

    const { value } = event.currentTarget;

    const optionId = parseInt(value, 10);
    const option = getQuestionOptions(this.context, this.props.options).find(
      (_option: QuestionOption): boolean => _option.id === optionId
    ) || { value: undefined };

    updateAnswer(id, {
      option_ids: [Number.isNaN(optionId) ? value : optionId],
      value: option.value,
    });
  };

  renderOptions(): JSX.Element[] {
    const { id: questionId, options, answer } = this.props;

    return getQuestionOptions(this.context, options).map(
      (option, index): JSX.Element => {
        const {
          id,
          texts: { label },
        } = option;

        const isSelected = (answer.option_ids || []).includes(id);

        return (
          <div
            className="multiple-choice-option form-check"
            key={`multiple-choice-${id}`}
          >
            <input
              type="radio"
              className="multiple-choice-radio form-check-input"
              value={id}
              checked={isSelected}
              name={`multiple_choice_${questionId}_options`}
              tabIndex={index}
              id={`multiple_choice_${questionId}_option_${id}`}
              onChange={this.handleOptionClick}
            />
            <label
              className="multiple-choice-label form-check-label"
              aria-label={label}
              htmlFor={`multiple_choice_${questionId}_option_${id}`}
            >
              <TemplateText text={label} />
            </label>
          </div>
        );
      }
    );
  }

  render(): JSX.Element {
    const {
      texts: { question },
    } = this.props;

    return (
      <section
        className="additional-question-wrapper"
        data-testid="multiple-choice"
      >
        <h2 className="question-text text-left">
          <TemplateText text={question} />
        </h2>

        <div className="question-options-wrapper multiple-choice-options clearfix">
          {this.renderOptions()}
        </div>
      </section>
    );
  }
}

export default MultipleChoice;
