import { useContext, useState, useEffect } from 'react';
import parseLiquid from 'survey/services/LiquidParser';
import AppContext from 'survey/contexts/AppContext';

export default function useLiquidTemplate(
  template: string,
  fallback?: string | undefined
): string {
  const appContext = useContext(AppContext);
  const [parsedText, setParsedText] = useState('');

  useEffect(() => {
    let mounted = true;
    parseLiquid(template, appContext).then(parsedTemplate => {
      if (mounted) {
        if (parsedTemplate.length) {
          return setParsedText(parsedTemplate);
        }

        return setParsedText(fallback || '');
      }
      return false;
    });


    return () => {
      mounted = false;
    };
  });

  return parsedText;
}
