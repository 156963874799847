import DOMPurify from 'dompurify';
import { TemplateTextProps } from 'survey/types';
import useLiquidTemplate from 'survey/hooks/useLiquidTemplate';

function TemplateText({
  text,
  fallback,
  escape = true,
  allowATags = false,
}: TemplateTextProps): JSX.Element {
  const parsedText = useLiquidTemplate(text || '', fallback).trim();

  if (escape) {
    return (
      <>
        <span>{parsedText}</span>
      </>
    );
  }

  let purifyConfig = {};
  if (allowATags === false) {
    purifyConfig = { FORBID_TAGS: ['a'] };
  }


  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(parsedText.trim(), purifyConfig),
        }}
      />
    </>
  );
   
}

export default TemplateText;
