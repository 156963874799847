import { Component } from 'react';

import AppContext from 'survey/contexts/AppContext';
import { getQuestionOptions } from 'survey/selectors';
import { QuestionComponentProps } from 'survey/types';

import { Tile, TemplateText } from 'survey/components';

class MultipleSelectGrid extends Component<QuestionComponentProps> {
  static contextType = AppContext;

  handleTileClick = (optionId: number): void => {
    const { options, id, updateAnswer } = this.props;

    // Find what options should be selected after click
    const selectedOptions = options.filter((option): boolean => {
      const selectingOption = optionId === option;
      return this.isTileSelected(option) ? !selectingOption : selectingOption;
    });

    updateAnswer(id, { option_ids: selectedOptions as number[] });
  };

  isTileSelected = (optionId: number): boolean => {
    const { answer } = this.props;
    return answer && answer.option_ids
      ? answer.option_ids.includes(optionId)
      : false;
  };

  renderTiles(): JSX.Element[] {
    const { options } = this.props;

    return getQuestionOptions(this.context, options).map(
      (option, index): JSX.Element => {
        const {
          value,
          texts: { label },
        } = option;

        return (
          <Tile
            id={option.id}
            key={option.id}
            index={index}
            value={value}
            text={label}
            selected={this.isTileSelected(option.id)}
            handleTileClick={this.handleTileClick}
          />
        );
      }
    );
  }

  render(): JSX.Element {
    const {
      id,
      texts: { question },
    } = this.props;

    return (
      <section
        className="question-wrapper multiple-select-grid-wrapper"
        data-testid={`multiple-select-grid-${id}`}
      >
        <h2 className="question-text">
          <TemplateText text={question} />
        </h2>

        <div className="question-options-wrapper multiple-select-grid-options clearfix">
          {this.renderTiles()}
        </div>
      </section>
    );
  }
}

export default MultipleSelectGrid;
