import { Component } from 'react';

import { ScalarQuestionProps } from 'survey/types';
import { Tile, TemplateText } from 'survey/components';
import classNames from 'classnames';
import AppContext from 'survey/contexts/AppContext';
import { getQuestionOptions } from 'survey/selectors';

class ScalarQuestion extends Component<ScalarQuestionProps> {
  static contextType = AppContext;

  isSelected(value: number): boolean {
    const { answer, selectedCheck } = this.props;

    return selectedCheck ? selectedCheck(value) : value === answer.value;
  }

  renderTiles(): JSX.Element[] {
    const { options, tileComponent, handleTileClick } = this.props;
    const TileComponent = tileComponent || Tile;
    const filteredOptions = getQuestionOptions(this.context, options);

    return filteredOptions.map((option): JSX.Element => {
      const {
        id,
        value,
        texts: { label },
      } = option;

      return (
        <TileComponent
          id={id}
          value={value}
          index={value}
          key={`scalar-option/${id}`}
          text={label || value}
          selected={this.isSelected(Number(value))}
          handleTileClick={handleTileClick}
        />
      );
    });
  }

  render(): JSX.Element {
    const {
      texts: { question, min, max },
      className,
    } = this.props;

    return (
      <>
        <section
          className={classNames(
            'scalar-question-wrapper',
            'question-wrapper',
            className
          )}
          data-testid={className}
        >
          <h2 className="question-text">
            {question && <TemplateText text={question} />}
          </h2>

          <div className="question-options-wrapper scalar-question-options">
            {this.renderTiles()}
          </div>
        </section>

        <div className="scalar-question-labels-wrapper">
          {min && <span className="scalar-question-label">{min}</span>}
          {max && <span className="scalar-question-label">{max}</span>}
        </div>
      </>
    );
  }
}

export default ScalarQuestion;
