import queryString from 'query-string';
import App from './App';
import * as Sentry from '@sentry/browser';
import { RouteComponentProps } from 'react-router';
import { AppState } from 'survey/types';

interface AppRouteParams {
  uuid: string;
}

class Preview extends App {
  constructor(props: RouteComponentProps<AppRouteParams>) {
    super(props);
    Sentry.close();
  }

  saveAnswerFromUrl = (): void => undefined;

  saveAnswers = (): void => undefined;

  surveyURL = (): string => {
    const {
      match: { params },
      location: { search },
    } = this.props;
    const { api_base_url } = this.state; // eslint-disable-line @typescript-eslint/naming-convention

    const { configuration_id: configurationId } = queryString.parse(search);

    return configurationId
      ? `${api_base_url}/api/v1/survey_previews${search}`
      : `${api_base_url}/api/v1/survey_configurations/preview/${params.uuid}`;
  };

  setPageFromUrl = (): void => {
    const {
      location: { search },
    } = this.props;
    const { page: pageNo } = queryString.parse(search, { parseNumbers: true });

    if (!pageNo) return;

    this.setState({ currentPage: pageNo } as AppState);
  };
}

export default Preview;
