import { useContext } from 'react';

import { QuestionComponentProps } from 'survey/types';
import { TemplateText, MultipleChoice, EmployeeBio } from 'survey/components';
import AppContext from 'survey/contexts/AppContext';

const Recovery = (props: QuestionComponentProps): JSX.Element => {
  const appContext = useContext(AppContext);

  const {
    employee: {
      first_name, // eslint-disable-line @typescript-eslint/naming-convention
      image,
    },
  } = appContext;

  const {
    texts: { recovery_message }, // eslint-disable-line @typescript-eslint/naming-convention
  } = props;

  return (
    <>
      <section className="recovery-header-wrapper">
        <section className="profile-wrapper">
          <div className="profile">
            <div className="picture-wrapper">
              <div className="picture">
                <img src={image} alt={first_name} />
              </div>
            </div>
          </div>
        </section>
        <div className="recovery-message-wrapper">
          <div className="recovery-message">
            <TemplateText text={recovery_message} />
          </div>
          <EmployeeBio hideBioText />
        </div>
      </section>
      <MultipleChoice {...props} />
    </>
  );
};

export default Recovery;
