import { Component } from 'react';
import classNames from 'classnames';

import { TileProps } from 'survey/types';

class StarRatingTile extends Component<TileProps> {
  handleTileClick = (): void => {
    const { id, value, handleTileClick } = this.props;

    handleTileClick(id, value);
  };

  render(): JSX.Element {
    const { text, subtitle, value, selected, index } = this.props;

    return (
      <div className="star-rating-tile">
        <button
          tabIndex={index}
          aria-label={`${text} stars`}
          type="button"
          className={classNames('star-button', {
            filled: selected,
            empty: !selected,
          })}
          onClick={this.handleTileClick}
          data-testid={`star-button-${value}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="star-svg"
            data-name="star"
            viewBox="0 0 51.92 49.38"
          >
            <polygon
              className="star-polygon"
              points="25.96 0 19.83 18.86 0 18.86 16.04 30.52 9.92 49.38 25.96 37.72 42.01 49.38 35.88 30.52 51.92 18.86 32.09 18.86 25.96 0"
            />
          </svg>
        </button>

        {subtitle && (
          <div className="tile-subtitle" data-testid="tile-subtitle">
            {subtitle}
          </div>
        )}
      </div>
    );
  }
}

export default StarRatingTile;
