import StatusPage from './StatusPage';
import SurveyFinishedImage from 'survey/assets/images/survey-finished.svg';


const FinishedPage = (): JSX.Element => {

  return (
    <StatusPage
      image={SurveyFinishedImage}
      headerText="Survey Finished"
    >
      Your responses to this survey have already been recorded.
      <br />
      No further answers can be recorded for this survey at this time.
    </StatusPage>
  );
};

export default FinishedPage;
