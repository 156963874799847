import { useContext } from 'react';

import { EmployeeBio } from 'survey/components';
import AppContext from 'survey/contexts/AppContext';
import useLiquidTemplate from 'survey/hooks/useLiquidTemplate';
import { EmployeeHeaderProps } from 'survey/types';

const EmployeeHeader = ({ question }: EmployeeHeaderProps): JSX.Element => {
  const appContext = useContext(AppContext);

  const {
    employee: {
      first_name, // eslint-disable-line @typescript-eslint/naming-convention
      image,
      header_text, // eslint-disable-line @typescript-eslint/naming-convention
    },
  } = appContext;

  const {
    texts: {
      opening_line, // eslint-disable-line @typescript-eslint/naming-convention
    },
  } = question;

  const headerText = useLiquidTemplate(opening_line || header_text);

  return (
    <section
      className="intro-wrapper text-center"
      data-testid="employee-header"
    >
      <h1 className="intro-title">{headerText}</h1>

      <section className="profile-wrapper">
        <div className="profile">
          <div className="picture-wrapper">
            <div className="picture">
              <img src={image} alt={first_name} />
            </div>
          </div>
          <EmployeeBio />
        </div>
      </section>
    </section>
  );
};

export default EmployeeHeader;
