const StatusPage = ({
  image,
  headerText,
  children,
}: {
  image: string | undefined;
  headerText: string;
  children: React.ReactNode;
}): JSX.Element => {

  return (
    <section className="status-page" data-testid="status-page">
      <img className="status-image" src={image} />
      <div className="status-header">{headerText}</div>
      <div className="status-body">{children}</div>
    </section>
  );
};

export default StatusPage;
