import {
  Answer,
  AnswerState,
  AppState,
  QuestionEntity,
  QuestionOption
} from 'survey/types';

import { evalCondition } from 'survey/surveyUtils';

export function getCurrentPageQuestions(context: AppState): QuestionEntity[] {
  const { currentPage, entities } = context;

  if (!entities) return [];

  const { questions } = entities.pages[currentPage];

  return questions
    .map((questionId: number): QuestionEntity => entities.questions[questionId])
    .filter((question: QuestionEntity): boolean =>
      evalCondition(question.condition, context)
    );
}

export function getQuestionOptions(
  context: AppState,
  options: number[]
): QuestionOption[] {
  const { entities } = context;

  if (!entities) return [];

  return options.map(
    (optionId: number): QuestionOption => entities.options[optionId]
  );
}

export function getQuestionAnswer(
  context: AppState,
  questionId: number
): Answer {
  const { answers } = context;

  return answers[questionId] || {};
}

export function getAnswersForSubmit(context: AppState): Answer[] {
  const { answers, currentPage, entities } = context;

  if (!entities) return [];

  const answersAcc: AnswerState = {};

  Object.keys(entities.pages)
    .map((i): number => parseInt(i, 10))
    .filter((i): boolean => i <= currentPage + 1)
    .sort()
    .forEach((pageIndex): void => {
      entities.pages[pageIndex].questions.forEach((id): void => {
        const question = entities.questions[id];

        if (evalCondition(question.condition, context)) {
          answersAcc[id] = answers[id] || { question_id: id, option_ids: [] };
          answersAcc[id] = {
            ...answersAcc[id],
            seen_option_ids: question.options,
          };
        }
      });
    });

  return Object.values(answersAcc);
}
