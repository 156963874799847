import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ErrorBoundary } from 'survey/components';
import App from './App';
import Preview from './Preview';

export const AppRouter = (): JSX.Element => (
  <Router>
    <>
      <Route
        path="/surveys/:uuid"
        render={(routeProps): JSX.Element => (
          <ErrorBoundary>
            <App {...routeProps} />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/preview/:uuid?"
        render={(routeProps): JSX.Element => (
          <ErrorBoundary>
            <Preview {...routeProps} />
          </ErrorBoundary>
        )}
      />
    </>
  </Router>
);

export default AppRouter;
