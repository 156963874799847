import { Component, Fragment } from 'react';

import { SurveyParamsAnswer, PageProps, QuestionEntity } from 'survey/types';

import {
  EmployeeHeader,
  StarRating,
  MultipleSelectGrid,
  Rewards,
  Scalar,
  Comment,
  MultipleChoice,
  Recovery
} from 'survey/components';

import AppContext from 'survey/contexts/AppContext';
import { getQuestionAnswer, getCurrentPageQuestions } from 'survey/selectors';

class Page extends Component<PageProps> {
  static contextType = AppContext;

  componentDidMount(): void {
    this.saveSeenOptionsForPage();
  }

  saveSeenOptionsForPage = (): void => {
    const { saveAnswers } = this.context;
    const questions = getCurrentPageQuestions(this.context);

    const seenAnswers = questions.map(question => ({
      question_id: question.id,
      seen_option_ids: question.options,
    })) as SurveyParamsAnswer[];

    if (saveAnswers) saveAnswers({ answers: seenAnswers, finished: false });
  };

  renderQuestion = (question: QuestionEntity): JSX.Element | null => {
    const { updateAnswer } = this.props;
    const { id, component, ...rest } = question;
    const answer = getQuestionAnswer(this.context, question.id);
    const questionProps = { ...rest, updateAnswer, id };

    /*
    The survey data endpoints are tightly coupled to the names of these compoennts
    (e.g., /api/v1/survey_configurations/preview/)

    They return a "component" field with a string value in each "question" type it returns.
    Those are used to select the React component to render.
    */
    const QuestionComponent = {
      MultipleChoice,
      MultipleSelectGrid,
      Rewards,
      Scalar,
      StarRating,
      Recovery,
    }[component];

    return QuestionComponent ? (
      <QuestionComponent answer={answer} {...questionProps} />
    ) : null;
  };

  renderComment = (question: QuestionEntity): JSX.Element | null => {
    const { updateAnswer } = this.props;
    const { id, texts } = question;
    const answer = getQuestionAnswer(this.context, question.id);

    return (
      <Comment
        questionId={id}
        answer={answer}
        updateAnswer={updateAnswer}
        placeholder={texts.comment_placeholder}
      />
    );
  };

  renderQuestionComponents = (question: QuestionEntity): JSX.Element | null => {
    const {
      id,
      show_comment, // eslint-disable-line @typescript-eslint/naming-convention
      metadata,
    } = question;

    return (
      <Fragment key={`question/${id}`}>
        {metadata.show_employee_profile && (
          <EmployeeHeader question={question} />
        )}
        {this.renderQuestion(question)}
        {show_comment && this.renderComment(question)}
      </Fragment>
    );
  };

  renderQuestions(): (JSX.Element | null)[] | null {
    const questions = getCurrentPageQuestions(this.context);

    return questions.map(this.renderQuestionComponents);
  }

  render(): JSX.Element {
    const { submitPage, currentPage } = this.props;

    /* eslint-disable @typescript-eslint/naming-convention */
    const { answers, result, global_texts } = this.context;
    const hasAnswers = Object.keys(answers).length > 0;
    /* eslint-enable @typescript-eslint/naming-convention */

    const isLastPage = currentPage === result.length;
    const submitText = isLastPage ? global_texts.complete : global_texts.next;

    return (
      <div className="survey-wrapper">
        {this.renderQuestions()}

        {hasAnswers && (
          <section className="submit">
            <input
              type="submit"
              className="submit-button"
              data-testid="page-submit"
              value={submitText}
              onClick={submitPage}
            />
          </section>
        )}
      </div>
    );
  }
}

export default Page;
