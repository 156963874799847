import { useContext } from 'react';

import AppContext from 'survey/contexts/AppContext';
import { EmployeeBioProps } from 'survey/types';
import TemplateText from './TemplateText';

const EmployeeBio = ({
  hideBioText = false,
}: EmployeeBioProps): JSX.Element => {
  const appContext = useContext(AppContext);

  const {
    employee: {
      first_name, // eslint-disable-line @typescript-eslint/naming-convention
      text,
      location,
    },
    language,
  } = appContext;


  return (
    <div className="bio-wrapper">
      <div className="name">{first_name}</div>
      {['en-gb', 'en-ca', 'en'].includes(language) && (
        <>
          <div className="location">
            <TemplateText text={location} escape={false} />
          </div>
          {!hideBioText && <div className="bio">{text}</div>}
        </>
      )}
    </div>
  );
   
};

export default EmployeeBio;
