import { Component } from 'react';

import { QuestionComponentProps } from 'survey/types';
import { TemplateText, Tile } from 'survey/components';
import { getQuestionOptions } from 'survey/selectors';
import AppContext from 'survey/contexts/AppContext';

class Rewards extends Component<QuestionComponentProps> {
  static contextType = AppContext;

  handleTileClick = (optionId: number): void => {
    const { updateAnswer, id } = this.props;

    updateAnswer(id, { option_ids: [optionId] });
  };

  isTileSelected = (optionId: number): boolean => {
    const { answer } = this.props;
    return answer && answer.option_ids
      ? answer.option_ids.includes(optionId)
      : false;
  };

  renderOptions(): JSX.Element[] {
    const { options } = this.props;

    return getQuestionOptions(this.context, options).map(
      (option, index): JSX.Element => {
        const {
          value,
          light_image_url: imageUrl,
          texts: { label },
        } = option;

        return (
          <Tile
            value={value}
            index={index}
            imageUrl={imageUrl}
            id={option.id}
            key={`reward/${option.id}`}
            text={label}
            selected={this.isTileSelected(option.id)}
            handleTileClick={this.handleTileClick}
          />
        );
      }
    );
  }

  render(): JSX.Element {
    const {
      texts: { question },
    } = this.props;

    return (
      <section
        className="question-wrapper rewards-wrapper"
        data-testid="rewards"
      >
        <h2 className="question-text">
          <TemplateText text={question} />
        </h2>

        <div className="question-options-wrapper reward-options clearfix">
          {this.renderOptions()}
        </div>
      </section>
    );
  }
}

export default Rewards;
