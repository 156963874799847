import { useContext } from 'react';

import AppContext from 'survey/contexts/AppContext';

const css = (primaryColor: string): string => `
    .profile .bio-wrapper .name {
      color: ${primaryColor}
    }

    .scalar-question-options:hover .star-rating-tile .empty .star-polygon,
    .star-rating-tile .filled .star-polygon {
      fill: ${primaryColor}
    }

    .comment-wrapper textarea:focus {
      border-color: ${primaryColor}
    }

    .question-options-wrapper .tile-button-wrapper .btn.active,
    .question-options-wrapper .tile-button-wrapper .btn.active:hover,
    .submit-button,
    .submit-button:hover,
    .custom-link,
    .custom-link:hover {
      background-color: ${primaryColor}
    }
  `;

const Theme = (): JSX.Element | null => {
  const { brand } = useContext(AppContext);

  if (!brand.primary_color) return null;

  return <style>{css(brand.primary_color)}</style>;
};

export default Theme;
