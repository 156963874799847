import { SocialButtonProps } from 'survey/types';
import Clipboard from 'react-clipboard.js';

export default function FacebookShareButton({
  url,
  buttonText,
  handleClick,
  shareQuote,
}: SocialButtonProps): JSX.Element {
  const onClick = (): void => {
    window.open(
      url,
      'popUpWindow',
      'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
    );
    handleClick();
  };

  return (
    <Clipboard
      component="button"
      className="btn btn-block social-btn facebook spec-facebook-share"
      aria-label="Copy Quote and Share on Facebook"
      data-clipboard-text={shareQuote}
      onSuccess={onClick}
    >
      <i className="fab fa-facebook-f" />
      <span>{buttonText}</span>
    </Clipboard>
  );
}
