import { createContext } from 'react';
import { InitializationContextAttributes } from '../types';

export const initialState: InitializationContextAttributes = {
  REACT_APP_API_BASE_URL: '',
  REACT_APP_ENV: '',
  REACT_APP_FACEBOOK_APP_ID: '',
  REACT_APP_RESPONSE_API_BASE_URL: '',
  REACT_APP_SENTRY_DSN: '',
  APM_URL: '',
  APM_ENABLED: false,
};

export default createContext<InitializationContextAttributes>(
  initialState
);
