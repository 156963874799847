import { useContext } from 'react';

import { AppState } from 'survey/types';
import AppContext from 'survey/contexts/AppContext';

const Header = (): JSX.Element | null => {
  const appContext = useContext<AppState>(AppContext);

  if (appContext.brand) {
    return (
      <header className="header" data-testid="header">
        <img src={appContext.brand.logo} alt={appContext.brand.name} />
      </header>
    );
  }

  return null;
};

export default Header;
