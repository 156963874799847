import { Liquid } from 'liquidjs';
import { AppState } from '../types';

const parseLiquid = (
  text: string | undefined,
  context: AppState
): Promise<string> => {
  const engine = new Liquid();

  return engine
    .parseAndRender(text || '', context)
    .catch(() => Promise.resolve(text || ''));
};

export default parseLiquid;
