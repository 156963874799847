import { useContext } from 'react';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import InitializationContext from 'survey/contexts/InitializationContext';
import ThankYouPage from './ThankYouPage';

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}
const ErrorBoundary = (props: ErrorBoundaryProps): React.ReactElement => {
  const initializationContext = useContext(InitializationContext);
  const { apm } = initializationContext;

  const handleError = (error: Error): void => {
    if (error.message.match(/ResizeObserver loop limit exceeded/)) return;
    if (apm) apm.captureError(error);
  };

  return (
    <Sentry.ErrorBoundary fallback={<ThankYouPage />} onError={handleError}>
      {props.children || null}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
