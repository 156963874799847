import { Component } from 'react';

import { ScalarQuestionWrapperProps } from 'survey/types';
import { ScalarQuestion } from 'survey/components';

class Scalar extends Component<ScalarQuestionWrapperProps> {
  handleTileClick = (optionId: number, value: number): void => {
    const { updateAnswer, id } = this.props;

    updateAnswer(id, { option_ids: [optionId], value });
  };

  render(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const scalar_options = {
      component: this.constructor.name,
      handleTileClick: this.handleTileClick,
      className: 'nps-wrapper',
    };

    return <ScalarQuestion {...scalar_options} {...this.props} />;
  }
}

export default Scalar;
