import { useContext, useEffect, useCallback } from 'react';
import AppContext from 'survey/contexts/AppContext';
import { Friendbuy, FacebookButton, TwitterButton } from 'survey/components';
import useLiquidTemplate from 'survey/hooks/useLiquidTemplate';
import { evalCondition } from 'survey/surveyUtils';
import TemplateText from './TemplateText';

const ThankYouPage = (): JSX.Element => {
  const appContext = useContext(AppContext);
  const { texts, thank_you, facebook_app_id } = appContext; // eslint-disable-line @typescript-eslint/naming-convention

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    twitter_share_text,
    facebook_share_quote,
    custom_link,
    custom_link_text,
    custom_link_image,
  } = thank_you;
  /* eslint-enable @typescript-eslint/naming-convention */

  const twitterShareText = useLiquidTemplate(twitter_share_text || '');
  const shareQuote = useLiquidTemplate(facebook_share_quote || '');
  const customLink = useLiquidTemplate(custom_link || '');
  const customLinkText = useLiquidTemplate(custom_link_text || '');
  const customLinkImage = useLiquidTemplate(custom_link_image || '');

  const shouldRenderSocialComponents = useCallback(() => {
    const { expired } = appContext;
    return (
      thank_you.marketing_enabled &&
      !expired &&
      evalCondition(thank_you.condition, appContext)
    );
  }, [appContext, thank_you.marketing_enabled, thank_you.condition]);

  const onSocialEligible = useCallback(() => {
    if (!shouldRenderSocialComponents()) return;

    const { saveAnswers } = appContext;
    /* eslint-disable @typescript-eslint/naming-convention */
    const {
      social_action,
      twitter_share_enabled,
      facebook_share_enabled,
      twitter_follow_enabled,
      facebook_follow_enabled,
      friendbuy_enabled,
    } = thank_you;
    /* eslint-enable @typescript-eslint/naming-convention */

    const payload = {
      custom_link_eligible: social_action === 'custom_link',

      twitter_share_eligible:
        social_action === 'share' && twitter_share_enabled,
      facebook_share_eligible:
        social_action === 'share' && facebook_share_enabled,

      twitter_follow_eligible:
        social_action === 'follow' && twitter_follow_enabled,
      facebook_follow_eligible:
        social_action === 'follow' && facebook_follow_enabled,

      friendbuy_eligible: social_action === 'friendbuy' && friendbuy_enabled,
    };

    if (saveAnswers) saveAnswers(payload);
  }, [appContext, thank_you, shouldRenderSocialComponents]);

  useEffect(() => {
    onSocialEligible();
  }, [onSocialEligible]);

  function onSocialInitiated(socialInitiated: string): () => void {
    const { saveAnswers } = appContext;

    return (): void => {
      const payload = {
        [`${socialInitiated}_initiated`]: true,
        social_initiated_at: new Date(),
      };

      if (saveAnswers) saveAnswers(payload);
    };
  }

  function renderTwitterShareButton(): JSX.Element | null {
    const { twitter_share_enabled } = thank_you; // eslint-disable-line @typescript-eslint/naming-convention

    if (!twitter_share_enabled || !twitterShareText) return null;

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      twitterShareText
    )}`;

    return (
      <>
        <div className="share-suggestion-text">
          Recommended quote for your Twitter post:
        </div>
        <div className="tweet-text">{twitterShareText}</div>

        <TwitterButton
          url={twitterShareUrl}
          buttonText="Share"
          handleClick={onSocialInitiated('twitter_share')}
        />
      </>
    );
  }

  function renderFacebookShareButton(): JSX.Element | null {
    const {
      /* eslint-disable @typescript-eslint/naming-convention */
      facebook_share_enabled,
      facebook_share_link,
      facebook_share_hashtag,
      /* eslint-enable @typescript-eslint/naming-convention */
    } = thank_you;

    if (!facebook_share_enabled || !shareQuote) return null;

    const encodedHashtag = facebook_share_hashtag
      ? encodeURIComponent(`#${facebook_share_hashtag}`)
      : '';

    const parameters = [`app_id=${facebook_app_id}`, `href=${facebook_share_link}`, `hashtag=${encodedHashtag}`];
    const facebookShareUrl = `https://www.facebook.com/dialog/share?${parameters.join('&')}`;

    return (
      <>
        <div className="share-suggestion-text">
          Recommended quote for your Facebook post:
        </div>
        <div className="facebook-text">{shareQuote}</div>
        <FacebookButton
          shareQuote={shareQuote}
          url={facebookShareUrl}
          buttonText="Copy Quote and Share"
          handleClick={onSocialInitiated('facebook_share')}
        />
      </>
    );
  }

  function renderFacebookFollowButton(): JSX.Element | null {
    const { facebook_follow_enabled, facebook_follow_link } = thank_you; // eslint-disable-line @typescript-eslint/naming-convention

    if (!facebook_follow_enabled || !facebook_follow_link) return null;

    return (
      <FacebookButton
        url={facebook_follow_link}
        buttonText="Like on Facebook"
        handleClick={onSocialInitiated('facebook_follow')}
      />
    );
  }

  function renderTwitterFollowButton(): JSX.Element | null {
    const { twitter_follow_enabled, twitter_handle } = thank_you; // eslint-disable-line @typescript-eslint/naming-convention

    if (!twitter_follow_enabled || !twitter_handle) return null;

    return (
      <TwitterButton
        url={`https://twitter.com/${twitter_handle}`}
        buttonText="Follow on Twitter"
        handleClick={onSocialInitiated('twitter_follow')}
      />
    );
  }

  function renderCustomLink(): JSX.Element | null {
    const { custom_link_type } = thank_you; // eslint-disable-line @typescript-eslint/naming-convention

    if (!customLinkText && !customLinkImage) return null;

    const onClick = onSocialInitiated('custom_link');

    // Cleanup custom link
    let cleanedCustomLink = customLink.trim();
    if (!cleanedCustomLink.startsWith('http'))
      cleanedCustomLink = `http://${cleanedCustomLink}`;

    if (custom_link_type === 'text' && customLinkText) {
      return (
        <a
          onClick={onClick}
          href={cleanedCustomLink}
          title={customLinkText}
          target="_blank"
          className="btn btn-block custom-link"
          rel="noopener noreferrer"
        >
          {customLinkText}
        </a>
      );
    }

    if (custom_link_type === 'image' && customLinkImage) {
      return (
        <a
          onClick={onClick}
          href={cleanedCustomLink}
          title={customLinkText}
          target="_blank"
          className="custom-link-image"
          rel="noopener noreferrer"
        >
          <img src={customLinkImage} alt={customLinkText} />
        </a>
      );
    }

    return null;
  }

  function renderFriendbuy(): JSX.Element | null {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { friendbuy_enabled, friendbuy_site_id, friendbuy_widget_id } =
      thank_you;

    if (!friendbuy_enabled) return null;

    return (
      <Friendbuy siteId={friendbuy_site_id} widgetId={friendbuy_widget_id} />
    );
  }

  function renderSocialActions(): JSX.Element | null {
    const { social_action } = thank_you; // eslint-disable-line @typescript-eslint/naming-convention

    if (!shouldRenderSocialComponents()) return null;

    return (
      <div className="social-actions">
        {social_action === 'share' && renderTwitterShareButton()}
        {social_action === 'share' && renderFacebookShareButton()}
        {social_action === 'follow' && renderFacebookFollowButton()}
        {social_action === 'follow' && renderTwitterFollowButton()}
        {social_action === 'friendbuy' && renderFriendbuy()}
        {social_action === 'custom_link' && renderCustomLink()}
      </div>
    );
  }

  return (
    <section className="thanks text-center" data-testid="thank-you">
      <h1>
        <TemplateText
          text={
            texts.thank_you ||
            thank_you.texts.message ||
            'Thank you for your feedback!'
          }
          fallback="Thank you for your feedback!"
          escape={false}
          allowATags
        />
      </h1>

      {renderSocialActions()}
    </section>
  );
};

export default ThankYouPage;
