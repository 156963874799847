// import axios from 'axios';
import { AppState, EvalableCondition } from 'survey/types';

export function evalCondition(
  condition: EvalableCondition,
  context: AppState
): boolean {
  if (condition == null || condition === 'true') return true;

  const {
    answers,
    brand,
    employee,
    custom_properties, // eslint-disable-line @typescript-eslint/naming-convention
  } = context;

  /* eslint-disable @typescript-eslint/no-implied-eval */
  const Eval = new Function(
    'answers',
    'brand',
    'employee',
    'custom_properties',
    `return ${condition}`
  );
  /* eslint-enable @typescript-eslint/no-implied-eval */

  try {
    return Eval(answers, brand, employee, custom_properties);
  } catch (e) {
    return false;
  }
}
